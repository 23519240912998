import { reactive } from "vue";

const messageState = reactive({
  messages: []
});

function isPending() {
  return messageState.messages.some((message) => message.isPendingMsg === true);
}

const updateMessage = (message) => {
  const timestamp = Math.floor(new Date() / 1000);
  messageState.messages.push({ message, timestamp, isPending: false });
  removeMessageWithTiming(timestamp);
};

async function usePendingAlertMessage(callback, skip = false, pendingMsg = "等待中......") {
  if (skip) {
    const value = await callback();
    return value;
  }
  updatePendingMessage(pendingMsg);
  const value = await callback();
  removePendingMessage();
  return value;
}

function updatePendingMessage(message) {
  const timestamp = Math.floor(new Date() / 1000);
  const pendingMessageIndex = messageState.messages.findIndex((message) => message.isPendingMsg);
  if (pendingMessageIndex === -1) {
    messageState.messages.push({ message, timestamp, isPendingMsg: true });
  } else {
    messageState.messages[pendingMessageIndex].message = message;
    messageState.messages[pendingMessageIndex].timestamp = timestamp;
  }
}

function removePendingMessage() {
  const pendingMessageIndex = messageState.messages.findIndex((message) => message.isPendingMsg);
  if (pendingMessageIndex === -1) return;
  removeMessage(pendingMessageIndex, 1);
}

function removeMessageWithTiming(timestamp) {
  setTimeout(() => {
    messageState.messages.forEach((item, i) => {
      if (item.timestamp === timestamp) {
        removeMessage(i);
      }
    });
  }, 5000);
}

function removeMessage(index) {
  messageState.messages.splice(index, 1);
}

export default {
  isPending,
  usePendingAlertMessage,
  messageState,
  updateMessage
};
