import { reactive } from "vue";
import fetchFunction from "./fetchFunction";

const { getTokenFromCookie, fetchApi } = fetchFunction;

const employeeState = reactive({
  allDoctorData: [],
  beauticiansData: [],
  beauticianAAData: [],
  beauticiansAAForCareData: [],
  initUpdateEmployeeData: {},
  employeeSettingData: []
});

// GET

/** 取得所有醫師 (啟用中) */
async function getAllDoctorData() {
  const url = `/api/Employee/GetDoctorByActiveStatus?active=true`;

  const response = await fetchApi(url);

  employeeState.allDoctorData = response.data;
}

/** 取得所有醫美師 */
async function getBeauticians() {
  const url = `/api/Employee/GetEmployeeNotResignedData`;

  const response = await fetchApi(url);

  employeeState.beauticiansData = response.data;
}

/** 取得啟用中的高階醫美師 */
async function getBeauticianAAByActiveStatus() {
  const url = `/api/Employee/GetBeautician_AAByActiveStatus?active=true`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  employeeState.beauticianAAData = response.data;
}

/** 取得醫美師助理可代關心的高階醫美師 */
async function getBeauticiansAAForCare() {
  const url = `/api/Employee/GetBeauticiansAAForCare`;

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getTokenFromCookie()}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  employeeState.beauticiansAAForCareData = response.data;
}

/** 取得初始化更新員工的資料 */
async function getInitUpdateEmployeeData(id) {
  const url = `/api/Employee/GetInitUpdateEmployeeData?id=${id}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  employeeState.initUpdateEmployeeData = response.data;
}

/** 取全部員工資料 */
async function getEmployeeSettingData() {
  const url = `/api/Employee/GetEmployeeSettingData`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  employeeState.employeeSettingData = response.data;
}

// POST

/** 新增員工 */
async function addEmployee(data) {
  const url = `/api/Employee`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    body: JSON.stringify(data)
  };

  return await fetchApi(url, options);
}

// PATCH

/** 編輯員工 */
const editEmployee = async (data) => {
  const url = `/api/Employee`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "PATCH",
    body: JSON.stringify(data)
  };

  return await fetchApi(url, options);
};

export default {
  employeeState,
  getAllDoctorData,
  getBeauticians,
  getBeauticianAAByActiveStatus,
  getBeauticiansAAForCare,
  addEmployee,
  editEmployee,
  getInitUpdateEmployeeData,
  getEmployeeSettingData
};
