const BASE_URL = `${process.env.VUE_APP_API_URL}`;
const LOTTERYAPI_URL = `${process.env.VUE_APP_LOTTERYAPI_URL}`;
const SSO_URL = `${process.env.VUE_APP_SSO_URL}`;

function checkDomain(apiOptions) {
  if (!apiOptions) return BASE_URL;
  switch (apiOptions) {
    case "lotteryApi": {
      return LOTTERYAPI_URL;
    }
    case "ssoApi": {
      return SSO_URL;
    }
  }
  return BASE_URL;
}

export const fetchApi = async (url, defaultOptions, apiOptions = null) => {
  const headersJudge = defaultOptions && defaultOptions.headers;
  const methodJudge = defaultOptions && defaultOptions.method;
  const dataJudge = defaultOptions && defaultOptions.data;

  // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1N2UyZjZiNC02ZTIwLTQzYzYtYmQ4ZC1jMDg5ZDliNGRkM2QiLCJyb2xlIjoiQWRtaW5pc3RyYXRvciIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvdXNlcmRhdGEiOiJmYTE3ZjhiOS1jYWJkLTQwMzEtYWFjMy0xYWY1Y2JmYWQzYzAiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyYXRpb24iOiIxNjkzMjQ0MzI5IiwibmJmIjoxNjkzMTg0Mzg5LCJleHAiOjE2OTMyNDQzMjksImlhdCI6MTY5MzE4NDM4OSwiaXNzIjoiaGMifQ.l8LdA13fgjjeV52AcU8cHxMFznUtMQjb6ebLc3UNJUE`;

  const options = {
    headers: headersJudge
      ? defaultOptions.headers
      : {
          "Content-Type": "application/json"
        },
    method: methodJudge ? defaultOptions.method : "GET",
    credentials: "include"
  };

  if (dataJudge) {
    options.body = JSON.stringify(defaultOptions.data);
  }

  let domain = checkDomain(apiOptions);
  const res = await fetch(domain + url, options);
  const response = await res.json();
  if (res.ok) {
    return response;
  } else {
    throw new Error(response);
  }
};
