<script setup>
import { defineProps } from "vue";
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    default: 30
  },
  class: {
    type: String,
    default: ""
  },
  style: {
    type: Object
  }
});
</script>

<template>
  <svg
    class="svg-icon"
    :width="`${props.size}`"
    :height="`${props.size}`"
    aria-hidden="true"
    :class="props.class"
    :style="props.style"
  >
    <use :xlink:href="`#${props.name}`" />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
