import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import IconComponent from "@/components/IconComponent";
import pinia from "@/store/store.js";
import "vue-multiselect/dist/vue-multiselect.css";
import "./index.css";

const requireAll = (requireContext) => requireContext.keys().map(requireContext);
const req = require.context("./assets/stylesheets/icon", true, /\.svg$/);
requireAll(req);

const app = createApp(App);

// directive
app.directive("uppercase", {
  updated(el) {
    el.value = el.value.toUpperCase();
    el.dispatchEvent(new Event("input"));
  }
});

app.use(pinia).use(router).component("IconComponent", IconComponent).mount("#app");
