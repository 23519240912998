import {
  useAuthenticated,
  useCheckPermission,
  useCheckUserIpIsHonchen,
  useCheckUserIsAlreadyLogin,
  checkDoctorShiftAuth
} from "./authMiddleware";

function changeTitle(to) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
}

export const middleware = async (to) => {
  changeTitle(to);
  if (to.name === "loginIndex" || to.name === "no-permission") return true;

  // check doctor shift auth
  if (to.name === "doctorShift" || to.name === "doctorShiftDaily") {
    const isDoctorShiftAuth = await checkDoctorShiftAuth();
    if (!isDoctorShiftAuth) return { name: "no-permission" };
    return true;
  }

  // user is already login
  const isAlreadyLogin = useCheckUserIsAlreadyLogin();
  if (isAlreadyLogin) return true;

  // check if user is authenticated
  const isAuthenticated = await useAuthenticated();
  if (!isAuthenticated) return { name: "loginIndex" };

  // get user permission
  const user = await useCheckPermission();
  if (!user) return { name: "no-permission" };

  // check if user ip is honchen
  const isIpHonchen = await useCheckUserIpIsHonchen(user.role);
  if (!isIpHonchen) return { name: "no-permission" };

  return true;
};
