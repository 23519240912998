import { reactive } from "vue";
import fetchFunction from "./fetchFunction";

const appointmentState = reactive({
  year: null,
  month: null,
  date: null,
  today: {
    year: null,
    month: null,
    date: null
  },
  updateAppointmentData: [],
  selectedCustomer: {},
  clinicPeriodLastTime: {},
  calendarContent: "continuousOutpatientAmount",
  department: "AestheticMedicine",
  appointmentContent: "monthOfAppointment",
  couseReason: "",
  timeRange: [],
  filterQueryStatus: {
    continuousOutpatientNum: 1,
    isAppointment: true,
    morning: true,
    afternoon: true,
    evening: true
  },
  customerStoredValueData: {},
  voucherDepositData: {},
  offWorkTime: "",
  // data
  calendarData: [],
  doctorData: [],
  alternateCustomerData: [],
  daysOutpatientData: [],

  selectedDoctor: [],
  showCalendarData: [],
  calendar: [],

  scrollToTimePeriodString: "",
  daysOutpatients: []
});

/** 取得醫師資料 */
const getDoctors = async () => {
  // const token = fetchFunction.getTokenFromCookie();

  const url = `/api/Employee/GetDoctorByActiveStatus?active=true`;

  const options = {
    // headers: {
    //   'Content-Type': 'application/json',
    //   "Authorization" : `Bearer ${token}`
    // },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  let doctorData = response.data;

  const doctorDataLength = doctorData.length <= 4 ? doctorData.length : 4;

  for (let i = 0; i < doctorDataLength; i++) {
    doctorData[i].select = true;
  }

  appointmentState.doctorData = doctorData;
};

/** 取約診、候補的最新資料 */
const getUpdateAppointmentData = async (id) => {
  const url = `/api/Appointment/GetInitUpdateAppointmentData?id=${id}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  appointmentState.updateAppointmentData = response.data;
};

/** 確認取消候補/約診 */
const courseEnter = async (id) => {
  const putData = {
    cancelReason: appointmentState.couseReason,
    appointmentId: id
  };

  const token = fetchFunction.getTokenFromCookie();

  const url = `/api/Appointment/Cancel`;

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "PATCH",
    body: JSON.stringify(putData)
  };

  const response = await fetchFunction.fetchApi(url, options);

  return response;
};

/** 取得/重製已選擇醫師 */
const resetSelectedDoctor = () => {
  appointmentState.selectedDoctor = [];

  appointmentState.doctorData.forEach((item) => {
    if (item.select) {
      appointmentState.selectedDoctor.push(item);
    }
  });
};

/** 取日班表，醫美部與美容部 */
const getDaysOutpatientData = async () => {
  const token = fetchFunction.getTokenFromCookie();

  const date = `${appointmentState.year}-${appointmentState.month + 1 <= 9 ? "0" : ""}${appointmentState.month + 1}-${appointmentState.date <= 9 ? "0" : ""}${appointmentState.date}`;

  let url = `/api/Outpatient/GetDaysOutpatientData?departmentId=${appointmentState.department}&date=${date}${appointmentState.filterQueryStatus.morning ? `&clinicPeriods=Morning` : ""}${appointmentState.filterQueryStatus.afternoon ? `&clinicPeriods=Afternoon` : ""}${appointmentState.filterQueryStatus.evening ? `&clinicPeriods=Evening` : ""}`;

  appointmentState.selectedDoctor.forEach((item) => {
    url = `${url}&doctors=${item.id}`;
  });

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  appointmentState.daysOutpatientData = response.data;

  const { daysOutpatients, customerStoredValueData, voucherDepositData } = appointmentState.daysOutpatientData;

  appointmentState.customerStoredValueData = customerStoredValueData;
  appointmentState.voucherDepositData = voucherDepositData;

  // 取出顯示資料最後的時間

  const timeArr = Object.keys(daysOutpatients);

  if (timeArr.length === 0) return null;

  // 時間間格
  const hourIntervals = timeArr[1].split(":")[0] - timeArr[0].split(":")[0];
  const minuteIntervals = timeArr[1].split(":")[1] - timeArr[0].split(":")[1];

  const lastData = timeArr[timeArr.length - 1].split(":");

  let time = new Date(0, 0, 0, lastData[0], lastData[1]);

  time.setHours(time.getHours() + hourIntervals);
  time.setMinutes(time.getMinutes() + minuteIntervals);

  appointmentState.offWorkTime = `${time.getHours()}:${time.getMinutes() <= 9 ? 0 : ""}${time.getMinutes()}`;

  // 取跨時段

  appointmentState.timeRange = [];

  if (appointmentState.department === "AestheticMedicine") {
    for (let i = 0; i < timeArr.length; i++) {
      for (let j = 0; j < appointmentState.selectedDoctor.length; j++) {
        if (daysOutpatients[timeArr[i]][appointmentState.selectedDoctor[j].id].normalAppointment) {
          if (daysOutpatients[timeArr[i]][appointmentState.selectedDoctor[j].id].normalAppointment.timeRange > 1) {
            let itemRange =
              daysOutpatients[timeArr[i]][appointmentState.selectedDoctor[j].id].normalAppointment.timeRange;

            for (let k = i; k < i + itemRange; k++) {
              appointmentState.timeRange.push({
                operatorId:
                  daysOutpatients[timeArr[i]][appointmentState.selectedDoctor[j].id].normalAppointment.operatorId,
                time: timeArr[k],
                left: daysOutpatients[timeArr[i]][appointmentState.selectedDoctor[j].id].normalAppointment.left,
                lastTimeSlot: k + 1 === i + itemRange ? true : false
              });
            }
          }
        }
      }
    }
  }

  if (appointmentState.department === "Beauty") {
    for (let i = 0; i < timeArr.length; i++) {
      if (daysOutpatients[timeArr[i]].normalAppointment) {
        if (daysOutpatients[timeArr[i]].normalAppointment.timeRange > 1) {
          let itemRange = daysOutpatients[timeArr[i]].normalAppointment.timeRange;

          for (let k = i; k < i + itemRange; k++) {
            appointmentState.timeRange.push({
              operatorId: daysOutpatients[timeArr[i]].normalAppointment.operatorId,
              time: timeArr[k],
              left: daysOutpatients[timeArr[i]].normalAppointment.left,
              lastTimeSlot: k + 1 === i + itemRange ? true : false
            });
          }
        }
      }
    }
  }

  appointmentState.daysOutpatients = daysOutpatients;
};

/** 取今日日期 */
const getCurrentMonth = () => {
  const data = new Date();

  appointmentState.today = {
    month: data.getMonth() + 1,
    year: data.getFullYear(),
    date: data.getDate()
  };
  appointmentState.month = data.getMonth(); // 0 - 11
  appointmentState.year = data.getFullYear();
  appointmentState.date = data.getDate();
};

/** 取得早、午診最後時間 */
const getClinicPeriodLastTime = async () => {
  const url = `/api/Outpatient/GetClinicPeriodLastTime?departmentId=${appointmentState.department}&date=${appointmentState.year}-${appointmentState.month + 1}-${appointmentState.date}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  appointmentState.clinicPeriodLastTime = response.data;
};

/** 取得醫師連續空診 */
const getDoctorContinuousOutpatientData = async (judge) => {
  // 初始連續空診 (只抓空診數量)
  let url = `/api/Outpatient/GetInitDoctorContinuousOutpatientData?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;

  if (!judge) {
    // 更新連續空診 (抓連續空診 數量)
    url = `/api/Outpatient/GetDoctorContinuousOutpatientData?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;
  }

  if (appointmentState.selectedDoctor.length === 0) {
    resetSelectedDoctor();
    appointmentState.calendarData = [];
  }

  appointmentState.selectedDoctor.forEach((item) => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.showCalendarData = response.data;

  showCalendar(appointmentState.year, appointmentState.month);
};

/** 顯示當月的總日，並把上、下個月的零碎日子加入 */
const showCalendar = (year, month) => {
  let currentMonth = getCalendar(year, month, true);

  const firstWeekLen = currentMonth[0].length;
  const lastWeekLen = currentMonth[currentMonth.length - 1].length;

  if (firstWeekLen < 7 && firstWeekLen !== 0) {
    let lastMonth = getCalendar(year, month - 1, false);

    currentMonth[0] = lastMonth[lastMonth.length - 1].concat(currentMonth[0]);
  }

  if (lastWeekLen < 7 && lastWeekLen !== 0) {
    let nextMonth = getCalendar(year, month + 1, false);
    currentMonth[currentMonth.length - 1] = currentMonth[currentMonth.length - 1].concat(nextMonth[0]);
  }

  for (let i = 0; i < currentMonth.length; i++) {
    if (currentMonth[i].length === 0) {
      currentMonth.splice(i, 1);
    }
  }

  // 不是內地，就刪除星期天
  // if (!this.china) {
  //   this.dayLength = 6;
  //   for(let i = 0; i < currentMonth.length; i++) {
  //     currentMonth[i].splice(0, 1);
  //   }
  // }

  appointmentState.calendar = currentMonth;
  appointmentState.calendarData = currentMonth;
};

/** 取得當月的總日和資料 */
const getCalendar = (year, month, j) => {
  let calendar = [];
  let week = [];
  let data = new Date(year, month + 1, 1);

  data.setHours(data.getHours() - 1); // 下個月 - 1 小時，目的是轉換成當月的最後一天

  const allDate = data.getDate(); // 取得當月總日，例: 1 月，共 31 日

  for (let i = 1; i <= allDate; i++) {
    let morning = [];
    let afternoon = [];
    let evening = [];

    if (j) {
      for (let g = 0; g < appointmentState.showCalendarData.length; g++) {
        if (i === appointmentState.showCalendarData[g].day) {
          if (appointmentState.showCalendarData[g].clinicPeriod === "Morning") {
            morning.push(appointmentState.showCalendarData[g]);
          }

          if (appointmentState.showCalendarData[g].clinicPeriod === "Afternoon") {
            afternoon.push(appointmentState.showCalendarData[g]);
          }

          if (appointmentState.showCalendarData[g].clinicPeriod === "Evening") {
            evening.push(appointmentState.showCalendarData[g]);
          }
        }
      }
    }

    let morningSortBySelectedDoctor = [];
    let afternoonSortBySelectedDoctor = [];
    let eveningSortBySelectedDoctor = [];

    if (appointmentState.department === "AestheticMedicine") {
      appointmentState.selectedDoctor.forEach((itemA) => {
        morning.forEach((itemB) => {
          if (itemA.id === itemB.doctor) {
            morningSortBySelectedDoctor.push(itemB);
          }
        });

        afternoon.forEach((itemB) => {
          if (itemA.id === itemB.doctor) {
            afternoonSortBySelectedDoctor.push(itemB);
          }
        });

        evening.forEach((itemB) => {
          if (itemA.id === itemB.doctor) {
            eveningSortBySelectedDoctor.push(itemB);
          }
        });
      });
    }

    let day = new Date(year, month, i).getDay(); // 星期 : 0 - 6
    if (month === 12) {
      month = 0;
      year += 1;
    }

    if (month === -1) {
      month = 11;
      year -= 1;
    }

    week.push({
      year: year,
      month: month + 1,
      date: i,
      day: day,
      isCurrentMonth: j,
      morning: appointmentState.department === "AestheticMedicine" ? morningSortBySelectedDoctor : morning,
      afternoon: appointmentState.department === "AestheticMedicine" ? afternoonSortBySelectedDoctor : afternoon,
      evening: appointmentState.department === "AestheticMedicine" ? eveningSortBySelectedDoctor : evening
    });

    if (day === 6 || i === allDate) {
      calendar.push(week);
      week = [];
    }
  }
  return calendar;
};

/** 年和月的數字切換 */
const timeSwitch = (year, month, judge) => {
  if (judge) {
    month -= 1;
  } else {
    month += 1;
  }

  if (month === 12) {
    month = 0;
    year += 1;
  }

  if (month === -1) {
    month = 11;
    year -= 1;
  }

  return { year, month };
};

/** 搜尋部門門診產生方式 */
async function findGenerateOutpatientWay() {
  const url = `/api/Department/GetGenerateOutpatientWay?id=${appointmentState.department}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  return response.data;
}

/** 切換醫美部或美容部 - 約診與已約診 */
const continuousOrReservedToggle = async (status, judge) => {
  appointmentState.calendarContent = status;

  // 產生資料方式
  const dataGenerationMethod = await findGenerateOutpatientWay();

  if (dataGenerationMethod === "Doctor") {
    resetSelectedDoctor();
    if (appointmentState.appointmentContent === "monthOfAppointment") {
      if (appointmentState.calendarContent === "continuousOutpatientAmount") getDoctorContinuousOutpatientData(judge);

      if (appointmentState.calendarContent === "reservedAmount") getDoctorReservedDaysAndAmount();
    }

    if (appointmentState.appointmentContent === "dateOfAppointment") getDaysOutpatientData();
  }

  if (dataGenerationMethod === "Department") {
    if (appointmentState.appointmentContent === "monthOfAppointment") {
      if (appointmentState.calendarContent === "continuousOutpatientAmount") getBeauticianContinuousOutpatientData();

      if (appointmentState.calendarContent === "reservedAmount") getDepartmentReservedDaysAndAmount();
    }

    if (appointmentState.appointmentContent === "dateOfAppointment") getDaysOutpatientData();
  }

  getClinicPeriodLastTime();
  findAlternateByDate();
};

/** 取得醫師已約診資料 */
const getDoctorReservedDaysAndAmount = async () => {
  let url = `/api/Outpatient/GetDoctorReservedDaysAndAmount?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;

  if (appointmentState.selectedDoctor.length === 0) {
    resetSelectedDoctor();
  }

  appointmentState.selectedDoctor.forEach((item) => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.showCalendarData = response.data;
  appointmentState.filterQueryStatus.continuousOutpatientNum = 1;
  showCalendar(appointmentState.year, appointmentState.month);
};

/** 取得美容部已約診資料  */
const getDepartmentReservedDaysAndAmount = async () => {
  let url = `/api/Outpatient/GetDepartmentReservedDaysAndAmount?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.showCalendarData = response.data;
  appointmentState.filterQueryStatus.continuousOutpatientNum = 1;
  appointmentState.selectedDoctor = [];
  showCalendar(appointmentState.year, appointmentState.month);
};

/** 美容部連續空診 */
const getBeauticianContinuousOutpatientData = async () => {
  let url = `/api/Outpatient/GetInitDepartmentContinuousOutpatientData?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.showCalendarData = response.data;
  appointmentState.filterQueryStatus.continuousOutpatientNum = 1;
  appointmentState.selectedDoctor = [];
  showCalendar(appointmentState.year, appointmentState.month);
};

/** 取得候補名單(月) */
const findAlternateByYearMonth = async () => {
  let url = `/api/Appointment/GetAlternateByYearMonth?departmentId=${appointmentState.department}&year=${appointmentState.year}&month=${appointmentState.month + 1}`;

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  appointmentState.selectedDoctor.forEach((item) => {
    url = `${url}&doctors=${item.id}`;
  });

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.alternateCustomerData = response.data;
};

/** 取得候補名單(日) */
const findAlternateByDate = async () => {
  let url = `/api/Appointment/GetAlternateByDate?departmentId=${appointmentState.department}&date=${appointmentState.year}-${appointmentState.month + 1 <= 9 ? 0 : ""}${appointmentState.month + 1}-${appointmentState.date <= 9 ? 0 : ""}${appointmentState.date}`;

  appointmentState.selectedDoctor.forEach((item) => {
    url = `${url}&doctors=${item.id}`;
  });

  const token = fetchFunction.getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchFunction.fetchApi(url, options);

  if (response.statusCode >= 400) return null;

  appointmentState.alternateCustomerData = response.data;
};

/** 候補時間轉換中文 */
const clinicPeriodToCh = (name) => {
  switch (name) {
    case "Morning":
      return "早診";
    case "Afternoon":
      return "午診";
    case "Evening":
      return "晚診";
    case "AllDay":
      return "全天";
  }
};

/** 轉換時間(分針)刪除秒的格式 */
const convertTimeFormatDeleteSecond = (time) => {
  return time.substr(0, 5);
};

/** 轉換時間(年月日)斜線格式 */
const convertTimeFormatSlash = (time) => {
  const str = time || "";
  return str.replace(/-/g, "/ ");
};

/** 部門切換 */
const departmentToggle = (status) => {
  appointmentState.department = status;
  continuousOrReservedToggle(appointmentState.calendarContent);
};

export default {
  appointmentState,
  getDoctors,
  getCurrentMonth,
  resetSelectedDoctor,
  getDoctorContinuousOutpatientData,
  showCalendar,
  getCalendar,
  timeSwitch,
  continuousOrReservedToggle,
  findAlternateByDate,
  findAlternateByYearMonth,
  clinicPeriodToCh,
  convertTimeFormatDeleteSecond,
  convertTimeFormatSlash,
  departmentToggle,
  getDoctorReservedDaysAndAmount,
  getDaysOutpatientData,
  courseEnter,
  getClinicPeriodLastTime,
  getUpdateAppointmentData
};
