<template>
  <router-view />
</template>

<script>
import { provide } from "vue";
import store from "@/composition/store.js";
import checkIn from "@/composition/checkIn.js";
import track from "@/composition/track.js";

import fetchFunction from "@/composition/fetchFunction.js";
import alertMessage from "@/composition/alertMessage.js";
import textConversionTool from "@/composition/textConversionTool.js";
import accordion from "@/composition/accordion.js";

import Employee from "@/composition/Employee.js";
import LeaveRecord from "@/composition/LeaveRecord.js";
import MedicalRecord from "@/composition/MedicalRecord.js";
import Course from "@/composition/Course.js";
import courseType from "@/composition/courseType.js";
import prescribeMedicineRecord from "@/composition/prescribeMedicineRecord.js";

import { useAutoReload } from "./hooks/useAutoReload";

export default {
  setup() {
    provide("store", store);
    provide("checkIn", checkIn);
    provide("alertMessage", alertMessage);
    provide("track", track);

    provide("fetchFunction", fetchFunction);
    provide("textConversionTool", textConversionTool);

    provide("accordion", accordion);

    provide("Employee", Employee);
    provide("LeaveRecord", LeaveRecord);
    provide("MedicalRecord", MedicalRecord);
    provide("Course", Course);
    provide("courseType", courseType);
    provide("prescribeMedicineRecord", prescribeMedicineRecord);
    useAutoReload(6);
  }
};
</script>

<style lang="scss">
@import "./assets/stylesheets/all.scss";
</style>
