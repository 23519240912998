import { onMounted, onUnmounted, ref } from "vue";

/**
 * 偵測使用者滑鼠閒置幾分鐘自動重新整理
 * @method useAutoReload
 * @param {number} minutes - 閒置分鐘數
 * @returns {Object} - 包含重置計時器的方法
 */
export function useAutoReload(minutes) {
  const timer = ref(null);

  const resetTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
    timer.value = setTimeout(
      () => {
        window.location.reload();
      },
      minutes * 60 * 1000
    );
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  onMounted(() => {
    resetTimer();
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
  });

  onUnmounted(() => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
    window.removeEventListener("mousemove", handleUserActivity);
    window.removeEventListener("keydown", handleUserActivity);
    window.removeEventListener("touchstart", handleUserActivity);
    window.removeEventListener("scroll", handleUserActivity);
  });

  return {
    resetTimer
  };
}
