import { defineStore } from "pinia";
import { fetchApi } from "./fetch/generalFetch";

export default defineStore("auth Store", {
  state: () => ({
    jwtToken: null,

    /**
     * @param {string} userId
     * @param {string} name
     * @param {string} role
     */
    user: null
  }),
  actions: {
    setJwtToken(jwt) {
      this.jwtToken = jwt;
    },
    // POST
    /** 登入 */
    async postAuthLogin(data) {
      // clean user token
      this.jwtToken = null;
      this.user = null;

      const url = `/api/Auth/LogIn`;

      const options = {
        method: "POST",
        data
      };

      try {
        await fetchApi(url, options, "ssoApi");
        return { ok: true };
      } catch {
        return { ok: false };
      }
    },

    // POST
    /** 確認使用者登入 */
    async postCheckLogin() {
      const url = `/api/Auth/CheckLogIn`;

      const options = {
        method: "POST"
      };

      try {
        const res = await fetchApi(url, options, "ssoApi");
        const { jwtToken } = res;
        this.setJwtToken(jwtToken);
        return { ok: true };
      } catch {
        return { ok: false };
      }
    },

    // POST
    /** 登出 */
    async postLogOut() {
      const url = `/api/Auth/LogOut`;

      const options = {
        method: "POST"
      };

      await fetchApi(url, options, "ssoApi");
      this.setJwtToken(null);
      this.user = null;
    },

    // POST
    /**  根據TOKEN拿使用者資料 */
    async postPermission() {
      const url = `/api/Permission`;

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.jwtToken}`
        }
      };

      const res = await fetchApi(url, options);

      this.user = res.data;

      return res.data;
    },

    // POST
    /** 檢查IP是否為本公司 */
    async postCheckUserIpIsHonchen() {
      const url = `/api/Auth/checkIP`;

      const options = {
        method: "GET"
      };

      try {
        await fetchApi(url, options, "ssoApi");
        return true;
      } catch {
        return false;
      }
    }
  }
});
