import { reactive } from "vue";

const state = reactive({});

/** 年和月的數字切換 */
const timeSwitch = (year, month, judge) => {
  if (judge) {
    month -= 1;
  } else {
    month += 1;
  }

  if (month === 12) {
    month = 0;
    year += 1;
  }

  if (month === -1) {
    month = 11;
    year -= 1;
  }

  return { year, month };
};

/** 候補時間轉換中文 */
const clinicPeriodToCh = (name) => {
  switch (name) {
    case "Morning":
      return "早診";
    case "Afternoon":
      return "午診";
    case "Evening":
      return "晚診";
    case "AllDay":
      return "全天";
  }
};

/** 轉換時間(分針)刪除秒的格式，9:00:00 => 9:00 */
const convertTimeFormatDeleteSecond = (time) => {
  if (!time) return null;
  return time.substring(0, 5);
};

/** 轉換時間，刪除 T*/
// 2023-03-06T14:30:15.7561711+08:00 => { 2023-03-06, 14:30:15 }
const deleteTTimeFormat = (format) => {
  if (!format) return { date: null, time: null };

  const newformat = format.split("T");

  return { date: newformat[0], time: newformat[1].substring(0, 5) };
};

/** 轉換時間(年月日)斜線格式，2023-03-06 => 2023/ 03/ 06 */
const convertTimeFormatSlash = (time) => {
  const str = time || "";
  return str.replace(/-/g, "/ ");
};

/** 日期轉星期  */
const dateConversionWeek = (time) => {
  const date = new Date(time);
  const dayList = ["日", "一", "二", "三", "四", "五", "六"];

  const day = date.getDay();

  return dayList[day];
};

export default {
  state,
  timeSwitch,
  clinicPeriodToCh,
  convertTimeFormatDeleteSecond,
  convertTimeFormatSlash,
  deleteTTimeFormat,
  dateConversionWeek
};
