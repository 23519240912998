import { reactive } from "vue";
import fetchFunction from "./fetchFunction";

const { getTokenFromCookie, fetchApi } = fetchFunction;

const courseTypeState = reactive({
  courseTypeData: []
});

// GET

/** 取得所有療程類型  */
async function getCourseTypeData() {
  const url = `/api/CourseType/GetAll`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  courseTypeState.courseTypeData = response.data;
}

export default {
  courseTypeState,
  getCourseTypeData
};
