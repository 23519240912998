import { reactive } from "vue";
import fetchFunction from "./fetchFunction";

const { getTokenFromCookie, fetchApi } = fetchFunction;

const medicalRecordState = reactive({
  changeOperatorData: [],
  employeeStampData: [],
  otherPartHistoryData: [],
  consoleConfirmTagData: [],
  historicalMedicalRecordOption: [],
  doctorStampData: [],
  initExtraMedicalRecordData: [],
  historicalMedicalRecordData: [],
  medicalRecordStampData: [],
  historicalMedicalData: {}
});

// GET

/** 取得變更負責看診員工的資料 */
async function getChangeOperatorData(departmentId, customerId, date) {
  const url = `/api/MedicalRecord/GetChangeOperatorData?departmentId=${departmentId}&customerId=${customerId}&date=${date}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.changeOperatorData = response.data;
}

/** 根據條件取得詳細跟診資料(門診依部門產生時用) */
async function getDepartmentFollowUpDataBySearchCondition(departmentId, date, data) {
  let url = `/api/MedicalRecord/GetDepartmentFollowUpDataBySearchCondition?departmentId=${departmentId}&date=${date}`;

  const key = Object.keys(data);

  key.forEach((item) => {
    if (data[item]) {
      url += `&${item}=${data[item]}`;
    }
  });

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  return await fetchApi(url, options);
}

/** 取得員工的簽章資料 */
async function getEmployeeStampData(employeeId) {
  const url = `/api/MedicalRecord/GetEmployeeStampData?employeeId=${employeeId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.employeeStampData = response.data;
}

/** 更新病歷基本資料和收費 */
async function getOtherPartHistory(customerId, courseId) {
  const url = `/api/MedicalRecord/GetOtherPartHistory?customerId=${customerId}&courseId=${courseId}&limit=5`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.otherPartHistoryData = response.data;
}

/** 取得初始化加診的資料 */
async function getInitExtraMedicalRecordData(departmentId, customerId) {
  const url = `/api/MedicalRecord/GetInitExtraMedicalRecordData?departmentId=${departmentId}&customerId=${customerId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.initExtraMedicalRecordData = response.data;
}

/** 取得控台確認病歷的標籤資料 */
async function getConsoleConfirmTagData(customerId, date) {
  const url = `/api/MedicalRecord/GetConsoleConfirmTagData?customerId=${customerId}&appointmentDate=${date}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.consoleConfirmTagData = response.data;
}

/** 取得醫師簽章資料 */
async function getDoctorStampData() {
  const url = `/api/MedicalRecord/GetDoctorStampData`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.doctorStampData = response.data;
}

/** 取得客戶歷史病歷選項資料  */
async function getHistoricalMedicalRecordOption(courseTypeArr, customerId) {
  let url = `/api/MedicalRecord/GetHistoricalMedicalRecordOption?customerId=${customerId}`;

  courseTypeArr.forEach((item) => {
    url += `&courseTypeIdList=${item.id}`;
  });

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.historicalMedicalRecordOption = response.data;
}

/** 取得歷史病歷資料 */
async function getHistoricalMedicalRecordData(medicalRecordId) {
  const url = `/api/MedicalRecord/GetHistoricalMedicalRecordData?medicalRecordId=${medicalRecordId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };
  const response = await fetchApi(url, options);
  medicalRecordState.historicalMedicalRecordData = response.data;
  medicalRecordState.historicalMedicalData = response.data;
}

/** 取得病歷簽章資料 */
async function getMedicalRecordStampData(medicalRecordId) {
  const url = `/api/MedicalRecord/GetMedicalRecordStampData?medicalRecordId=${medicalRecordId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  medicalRecordState.medicalRecordStampData = response.data;
}

// PATCH

/** 修改變更負責子療程的員工 */
async function changeSubcourseOperator(item) {
  const url = `/api/MedicalRecord/ChangeSubcourseOperator`;

  const PATCHData = {
    medicalRecordId: item.medicalRecordId,
    subcourseId: item.subcourseId,
    beautician: item.beautician
  };

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "PATCH",
    body: JSON.stringify(PATCHData)
  };

  await fetchApi(url, options);
}

export default {
  medicalRecordState,
  getChangeOperatorData,
  getDepartmentFollowUpDataBySearchCondition,
  getEmployeeStampData,
  getOtherPartHistory,
  getInitExtraMedicalRecordData,
  getConsoleConfirmTagData,
  changeSubcourseOperator,
  getDoctorStampData,
  getHistoricalMedicalRecordOption,
  getHistoricalMedicalRecordData,
  getMedicalRecordStampData
};
