import { reactive } from "vue";
import fetchFunction from "./fetchFunction";

const { getTokenFromCookie, fetchApi } = fetchFunction;

const prescribeMedicineRecordState = reactive({
  prescribeMedicineRecordData: []
});

// GET

/** 取得病歷對應的開藥紀錄 */
async function getPrescribeMedicineRecord(medicalReocrdId) {
  const url = `/api/PrescribeMedicineRecord/GetByMedicalRecord?medicalReocrdId=${medicalReocrdId}`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "GET"
  };

  const response = await fetchApi(url, options);

  prescribeMedicineRecordState.prescribeMedicineRecordData = response.data;
}

// PATCH

/** 取得病歷對應的開藥紀錄 */
async function getByUpdateMedicalRecord(data) {
  const url = `/api/PrescribeMedicineRecord`;

  const token = getTokenFromCookie();

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    method: "PATCH",
    body: JSON.stringify(data)
  };

  return await fetchApi(url, options);
}

export default {
  prescribeMedicineRecordState,
  getPrescribeMedicineRecord,
  getByUpdateMedicalRecord
};
